import { sumBy } from "lodash";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useHistory } from "react-router";

import { routerPath } from "../../AppRouter";
import {
    usePlanExceededQuery,
    useSiteByIdQuery,
    useStripeCustomerPortalLinkQuery,
    ViewsBySiteId,
} from "../../generated";

interface PlanExceededProps {
    organisationId: string;
    siteId: string;
}

const BreakdownDetails: React.FC<{ siteId: string; viewCount: number }> = (
    props,
) => {
    const siteByIdResponse = useSiteByIdQuery({
        variables: { id: props.siteId },
    });
    if (siteByIdResponse.loading) {
        return null;
    }
    return (
        <div>
            <b>{siteByIdResponse.data?.siteById.name}:</b>{" "}
            {props.viewCount.toLocaleString()} views.
        </div>
    );
};

// exceeded by more than 10x
const OneMonth: React.FC<{ viewsBySiteId: ViewsBySiteId[] }> = (
    { viewsBySiteId },
) => {
    return (
        <div>
            <div>
                Well done! You had{" "}
                <b>
                    {sumBy(viewsBySiteId, "viewCount").toLocaleString()}
                </b>{" "}
                views across all your sites in the last month. This exceeds your free Nocodelytics quota by more than 10x.
            </div>
            <p>
                As a courtesy, we will allow you to continue using our service with no restrictions
                for another month.
            </p>
            <p>
                However, running Nocodelytics for popular sites like yours costs us money. Without your support, we cannot continue
                to run the service indefinitely. <b>Please consider upgrading to one of our paid plans</b>.
            </p>

        </div>
    );
};

// exceeded by less than 10x
const OneMonthSoft: React.FC<{ viewsBySiteId?: ViewsBySiteId[] }> = (
    { viewsBySiteId },
) => {
    return (
        <div>
            <div>
                Hello there! Just а polite reminder that you had{" "}
                <b>
                    {sumBy(viewsBySiteId, "viewCount").toLocaleString()}
                </b>{" "}
                views across all your sites in the last month. This exceeds your free Nocodelytics quota.
            </div>
            <p>
                As a courtesy, we will allow you to continue using our service <b>with no restrictions</b>.
            </p>
            <p>
                However, we are also a small business. Without your support we cannot continue
                to run the service. <b>Please consider upgrading to one of our paid plans.</b>
            </p>

        </div>
    );
};

const ThreeMonths: React.FC<{ viewsBySiteId: ViewsBySiteId[] }> = (
    { viewsBySiteId },
) => {
    return (
        <div>
            <div>
                Congratulation! You had a total of{" "}
                <b>
                    {sumBy(viewsBySiteId, "viewCount").toLocaleString()}
                </b>{" "}
                views across all your sites in the last three months. This means you have exceeded your free Nocodelytics quota by <b>more than 10x for three months</b>, or that your subscription payment is past due.
            </div>
            <p>
                We are happy we enjoy our product so much, but we must ask that you <b>upgrade to one of our paid plans. If you do not, we reserve the right to disable Nocodelytics for your sites
                    and delete your historical data</b>.
            </p>
            <p>
                Until you upgrade, you will not be able to view your dashboards, but we will still track statistics for your site. Once you upgrade, all data will be visible again.
            </p>

        </div>
    );
};

export const PlanExceeded: React.FC<PlanExceededProps> = (props) => {
    const history = useHistory();
    const [isModalOpen, setModalStatus] = useState(true);
    const planExceededResponse = usePlanExceededQuery({
        variables: { organisationId: props.organisationId },
    });
    // const { planExceeded, isFreePlanVeryExceeded, isFreePlanExceededContinuously, block } = planExceededResponse.data?.planExceeded;
    if (!planExceededResponse.data) return null;
    const planExceeded = planExceededResponse.data?.planExceeded.planExceeded;
    const isFreePlanVeryExceeded = planExceededResponse.data?.planExceeded.isFreePlanVeryExceeded;
    const isFreePlanExceededContinuously = planExceededResponse.data?.planExceeded.isFreePlanExceededContinuously;
    const block = planExceededResponse.data?.planExceeded.block;
    const stripeCustomerPortalLink = useStripeCustomerPortalLinkQuery();
    const viewsBySiteId = planExceededResponse.data?.planExceeded.viewsBySiteId;

    let showMonthSoft = planExceeded && !isFreePlanVeryExceeded && !isFreePlanExceededContinuously;
    let showMonthHard = planExceeded && isFreePlanVeryExceeded && !isFreePlanExceededContinuously;
    const showThreeMonth = isFreePlanExceededContinuously;

    return (
        <Modal
            centered
            show={isModalOpen}
            onHide={() => {
                if (!block) {
                    setModalStatus(false);
                } else
                    history.push(
                        routerPath.siteSettings({
                            organisationId: props.organisationId,
                            siteId: props.siteId,
                        }),
                    )
            }
            }
        >
            <Modal.Header closeButton>
                <Modal.Title>Free Plan Exceeded</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {showMonthSoft ? (<OneMonthSoft viewsBySiteId={viewsBySiteId} />) : null}
                {showMonthHard ? (<OneMonth viewsBySiteId={viewsBySiteId} />) : null}
                {showThreeMonth ? (<ThreeMonths viewsBySiteId={viewsBySiteId} />) : null}


                {(planExceededResponse.data?.planExceeded?.viewsBySiteId || [])
                    .length > 1 ? (
                    <div>
                        <div> Here is the breakdown:</div>
                        {planExceededResponse.data?.planExceeded.viewsBySiteId.map(
                            ({ siteId, viewCount }) => (
                                <BreakdownDetails
                                    key={siteId}
                                    siteId={siteId}
                                    viewCount={viewCount}
                                />
                            ),
                        )}
                    </div>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <Button
                    href={
                        stripeCustomerPortalLink.data?.stripeCustomerPortalLink
                    }
                    style={{
                        position: "relative",
                        overflow: "hidden",
                        animation: "fireworks 3s infinite",
                    }}
                >
                    Upgrade Now
                </Button>
            </Modal.Footer>
        </Modal >
    );
};
